@import "src/global-styles/colors";
@import "src/global-styles/typography";

.workspace-link {
  &__style {
    margin-top: 25px;
    margin-left: 30px;
    height: 70px;
    width: 85%;
    border-radius: 6px;
    background-color: $gray--light;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__label {
    height: 24px;
    width: 50%;
    color: $gray--darkest;
    font-family: $sans-bold;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 24px;
    margin-left: 30px;
  }
  &__info {
    font-size: 15px;
    margin-right: 2%;
  }
}