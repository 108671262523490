@import "src/global-styles/colors";
@import "src/global-styles/typography";

@font-face {
  font-family: "HendersonBCGSans-Regular";
  src: url("assets/fonts/HendersonBCGSans-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "HendersonBCGSans-Light";
  src: url("assets/fonts/HendersonBCGSans-Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "HendersonBCGSans-Thin";
  src: url("assets/fonts/HendersonBCGSans-Thin.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "HendersonBCGSans-Bold";
  src: url("assets/fonts/HendersonBCGSans-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "HendersonBCGSerif-Regular";
  src: url("assets/fonts/HendersonBCGSerif-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "HendersonBCGSerif-Bold";
  src: url("assets/fonts/HendersonBCGSerif-Bold.woff") format("woff");
  font-weight: 700;
}

html {
  font-size: inherit; //22.5%;
}

body {
  font-family: $sans-reg;
  font-size: 16px;
  color: $gray--darkest;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

li {
  list-style-type: none;
}

h1 {
  font-family: $sans-bold;
  font-size: 30px;
  color: $gray--darker;
  line-height: 1.25em;
}

h2 {
  font-family: $sans-bold;
  font-size: 22.5px;
  color: $gray--darker;
  line-height: 1.25em;
}

h3 {
  font-family: $sans-reg;
  font-size: 17.5px;
  color: $gray--darker;
  line-height: 1.25em;
}

h4 {
  font-family: $sans-bold;
  font-size: 15px;
  color: $gray--dark;
  line-height: 1.25em;
}