@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";

.layout {
  width: 96%;
  min-height: 800px;
  border-radius: 13px 13px 0 0;
  background-color: $gray--lightest;
  bottom: 40px;
  position: relative;
  margin-top: 115px;
  padding-top: 20px;

  &__page {
    background-color: $gray--light;
    width: 100%;
  }
}