@import "src/global-styles/colors";
@import "src/global-styles/typography";

.workspace {
  &__label {
    font-size: 40px;
    line-height: 50px;
    font-family: $serif-bold;
    &__position {
      margin-left: 30px;
      margin-top: 50px;
    }
  }
  &__icon {
    // position: relative;
    height: 503px;
    width: 619px;
    margin-top: 5%;
    max-width: 100%;
  }
}