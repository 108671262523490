@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";

.banner {
  background: url("../../assets/images/results-background.jpg");
  height: 180px;
  position: absolute;
  background-size: cover;

  &__breadcrumb {
    padding-top: 24px;
    padding-bottom: 10px;
    .link__home {
      height: 24px;
      width: 107px;
      color: $white;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      margin-left: 20px;
    }
    a.link__home + h1 {
      display: inline;
      margin-left: 14px;
      &:before {
        content: "\/";
        margin-right: 10px;
      }
    }
  }

  &__title {
    height: 24px;
    width: 107px;
    color: $white;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 0.8px;
  }
}
