// Primary brand colors
$bcg-green--dark: #00532f;
$bcg-green: #177b57;
$bcg-green--blue-green: #3ead93;
$bcggreentwo: #3ead92;
$bcg-green--light: #32c77f;
$bcg-green--light-blue-green: #3fad93;
$bcg-yellow: #e4ef39;
$bcg-light-yellow: #eee89a;
$bcg-red: #d50000;
$bcg-blue: #00bcd4;
$bcg-blue--light: #88c0fe;
$bcg-blue--dark: #1e455e;
$bcg-blue--darktwo: #1991eb;
$bcg-orange: #ff7002;
$bcggreenthree: #29ba74;
$bcggreenfour: #03522d;
$bcggreenfive: #197a56;
$bcggreensix: #ebfaf3;
$bcg-snow: #fffafa;
$bcggreenseven: #49aa7b;
$activegreen: #3ca46a;

// Grayscale
$black: #000000;
$blacktwo: #000000;
$blackthree: #111111;
$gray--blackish: #2b2826;
$gray--darkest: #333333;
$gray--darker: #707070;
$gray--dark: #b7b7b7;
$gray: #d9d9d9;
$gray--filler: #eee;
$gray--light: #f2f2f2;
$gray--lighter: #fafbfb;
$gray--lightest: #fafafa;
$gray--eclipse: #3a3a3a;
$bcggrayone: #2e384d;
$gray--bar: #e9e9e9;

$bcg-light-gray: #9a9a9a;
$gray--lighttwo: #e6e6e6;
$white: #ffffff;
$tab-menu: #f5f5f5;
$gray--brownish: #707070;
$gray--whitish: #dddbda;
$transparent: rgba(
  0,
  0,
  0,
  0
); // transparent convers to initial, rgba(0,0,0,0) translates to transparent.......... compilers these days
$checkboxgray: #979797;
$regulargray: #666666;
$regulargrey1: #888888;

//notifications
$notify-error: #fae6e6;
$notify-error-highlight: $bcg-red;
$notify-info: #eaf7f9;
$notify-info-highlight: $bcg-blue;
$notify-success: #ffffff;
$notify-shadow: #00000085;
$notify-success-highlight: #4caf50;
$notify-warn: #fff6d0;
$notify-warn-highlight: #ff9800;

//rating
$rating-absent: #ece3e3;
$rating-present: #f8ce0b;

%shadow--3 {
  box-shadow: 0px 5px 10px $regulargrey1, 0px -5px 10px $regulargrey1;
}

//shadow details
%shadow--1 {
  box-shadow: 0 1px 1px 0 rgba($black, 0.14), 0 2px 1px -1px rgba($black, 0.12), 0 1px 3px 0 rgba($black, 0.2);
}

%shadow--2 {
  box-shadow: 0 6px 10px 0 rgba($black, 0.14), 0 1px 18px 0 rgba($black, 0.12), 0 3px 5px -1px rgba($black, 0.2);
}

%shadow--white {
  box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.3);
}

//modal-color
$modal-white: rgba($white, 0.95);

//anchor-color
$anchor-color: #1e70bf;

:export {
  grayDarkest: $gray--darkest;
}
